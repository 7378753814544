<template>
  <MasterDetail
    ref="masterDetail"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :opts="opts"
    :cols="cols"
    :canEdit="false"
    :canDelete="false"
    :hasNewButton="false"
    :actionBarButtons="actionBarButtons"
    :contextOptions="contextMenu"
    :customResource="customResource"
  >
    <input
      ref="inputFile"
      :accept="acceptFileTypes"
      class="input-file"
      multiple
      type="file"
      @change="onInputChange"
    />

    <FormModal
      :cols="field"
      :opened.sync="taxModal.opened"
      title="Alterar taxa de juros"
      :value.sync="taxModal.data"
      @save="doSaveTax"
    />
  </MasterDetail>
</template>

<script>
import FormModal from "@/components/form-modal.vue";
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";
import { FieldTypeEnum } from "@/core/enums/field-types";
import { toNumber } from 'lodash';

export default {
  components: {
    FormModal,
    MasterDetail
  },
  data() {
    return {
      opts: {
        statusRubrica: [
          {
            id: 1,
            value: "Ativo"
          },
          {
            id: 0,
            value: "Inativo"
          }
        ]
      },
      taxModal: {
        data: {},
        opened: false
      }
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    acceptFileTypes() {
      return [
        // ".bmp",
        ".csv",
        ".doc",
        ".docx",
        // ".gif",
        // ".jpeg",
        // ".jpg",
        ".pdf",
        // ".png",
        ".pps",
        ".ppsx",
        ".ppt",
        ".pptx",
        ".psd",
        ".raw",
        // ".svg",
        // ".tiff",
        ".txt",
        // ".webp",
        ".xls",
        ".xlsx",
        ".xml",
        ".zip"
      ].join(",");
    },
    actionBarButtons() {
      return [
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          action: () => {
            this.$router.push({ name: "ativacaoped-projetos-form" });
          }
        },
        {
          text: "Alterar taxa de juros",
          icon: "mdi-percent-box-outline",
          action: async () => {
            const taxa_juros_anual = await this.doGetTax();
            this.taxModal.data = { taxa_juros_anual };
            this.taxModal.opened = true;
          }
        },
        {
          text: "Importar",
          icon: "mdi-upload-box-outline",
          action: () => {
            this.$refs.inputFile?.click();
          }
        }
      ];
    },
    contextMenu() {
      const contextOptions = [
        {
          name: "Alocação",
          show: true,
          cb: ({ id }) => {
            this.$router.push({
              name: "ativacaoped-projetos-form-edicao",
              params: { id },
              query: { tab: "alocacao" }
            });
          }
        },
        {
          name: "Timesheet",
          show: true,
          cb: ({ id }) => {
            this.$router.push({
              name: "ativacaoped-timesheet",
              params: { id }
            });
          }
        },
        { name: "sep", show: true },
        {
          name: "Editar",
          show: true,
          cb: ({ id }) => {
            this.$router.push({
              name: "ativacaoped-projetos-form-edicao",
              params: { id }
            });
          }
        }
      ];

      return contextOptions.filter(({ show }) => show);
    },
    cols() {
      return [
        {
          key: "id",
          name: "Código",
          hideInform: true,
          hideInTable: true,
          width: "50px"
        },
        {
          key: "nome",
          name: "Título do BP",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }]
        },
        {
          key: "data_inicio",
          name: "Data de início",
          type: this.$fieldTypes.DATE,
          rules: [{ rule: "required" }]
        },
        {
          key: "data_fim",
          name: "Data de término",
          type: this.$fieldTypes.DATE,
          rules: [{ rule: "required" }]
        },
        {
          key: "status",
          name: "Status",
          type: this.$fieldTypes.SELECT,
          rel: { to: "statusRubrica", key: "id", name: "value" },
          hideInform: true
        }
      ];
    },
    customResource() {
      const resource = this.apiResource(this.resourceUrl);
      return {
        get(args) {
          return resource.get(args).then(result => {
            return result.data ?? result;
          });
        }
      };
    },
    taxResource() {
      return this.apiResource(`/v1/ativacaoped/${this.clientId}/vpl-parametrizacao`);
    },
    field() {
      return [
        {
          key: "none",
          name:
            "Taxa de juros que será aplicada a todos os Business Plans que não tiverem uma taxa própria configurada.",
          type: FieldTypeEnum.SUBTITLE
        },
        {
          key: "taxa_juros_anual",
          name: "Taxa de juros",
          type: FieldTypeEnum.NUMBER,
          rules: [{ rule: 'required' }]
        }
      ];
    },
    resourceUrl() {
      return `/v1/ativacaoped/${this.clientId}/business-plans`;
    }
  },
  methods: {
    async doGetTax() {
      const response = await this.taxResource.get();
      return response.taxa_juros_anual;
    },
    async doSaveTax(data, close) {
      try {
        const { taxa_juros_anual } = data;
        await this.taxResource.save({ taxa_juros_anual: toNumber(taxa_juros_anual) });
  
        if (close) {
          close();
        }
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
      }
    },
    async onInputChange(event) {
      const files = Array.from(event.target.files);
      const MAX_FILE_SIZE = 10; // 10 MegaBytes
      const attachments = files.reduce((acc, file) => {
        if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
          this.notify({
            error: `O anexo ${file.name} excedeu o limite de ${MAX_FILE_SIZE}MB e não foi anexado.`
          });
          return acc;
        }

        return [...acc, file];
      }, []);

      if (!Array.isArray(attachments) || !attachments.length) {
        return;
      }

      try {
        const api = this.api();
        const url = `/v1/ativacaoped/${this.clientId}/business-plan/importar`;
        const formData = new FormData();
        attachments.forEach(file => {
          formData.append("anexos[]", file);
        });
        await api.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        this.$notify({
          group: "geral",
          duration: 5000,
          type: "sucess",
          title: "Importação concluída",
          text: `${attachments.length} importados com sucesso.`
        });
      } catch (error) {
        this.notify(error);
      }

      event.target.value = null; // Importante para poder voltar a anexar mais arquivos
    }
  }
};
</script>

<style lang="scss" scoped>
.input-file {
  display: none;
}
</style>
